import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/Home.vue')
  // },


  {
    path: '/',
    component: Layout,
    children: [
      {
        name: 'index',
        path: '/',
        component: () => import('../views/Index.vue')
      }
    ]
  },

  {
    path: '/gongsijianjie',
    component: Layout,
    children: [
      {
        name: 'gongsijianjie',
        path: '/',
        component: () => import('../views/GongsiJianjie.vue')
      }
    ]
  },

  {
    path: '/shichangtouxi',
    component: Layout,
    children: [
      {
        name: 'shichangtouxi',
        path: '/',
        component: () => import('../views/Shichangtouxi.vue')
      }
    ]
  },

  {
    path: '/yewumoshi',
    component: Layout,
    children: [
      {
        name: 'yewumoshi',
        path: '/',
        component: () => import('../views/Yewumoshi.vue')
      }
    ]
  },
  {
    path: '/hezuohuoban',
    component: Layout,
    children: [
      {
        name: 'hezuohuoban',
        path: '/',
        component: () => import('../views/Hezuohuoban.vue')
      }
    ]
  },
  {
    path: '/hezuojiameng',
    component: Layout,
    children: [
      {
        name: 'hezuojiameng',
        path: '/',
        component: () => import('../views/Hezuojiameng.vue')
      }
    ]
  },


  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  base: '/aixiyi',
  routes
})

export default router
