<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {

    }
  }
}
  </script>


<style lang="less" scoped>
/deep/
.v-application{
  width: 100%;
}
</style>
