<template>
  <v-app>
    <div class="bottom">
      <v-row justify="center" style="margin-top: 20px">
        <v-col cols="6">
          <ul>
            <li><router-link to="/">首&nbsp;&nbsp;&nbsp;&nbsp;页</router-link></li>
            <li><router-link to="/gongsijianjie">公司简介</router-link></li>
            <li><router-link to="/shichangtouxi">市场透析</router-link></li>
            <li><router-link to="/yewumoshi">业务模式</router-link></li>
            <li><router-link to="/hezuohuoban">合作伙伴</router-link></li>
            <li><router-link to="/hezuojiameng">合作加盟</router-link></li>
          </ul>
          <div class="btxt1" style="width: 100%; margin-left: 25px">
            电话：021—33050958<br />邮箱：Info@aixiyi.cn<br />地址：上海市闵行区联航路1505弄复地浦江中心1号楼1306室<br />
          </div>
        </v-col>
        <v-col cols="1">
          <div style="width: 140px; height: 140px;"><img style="width: 140px; height: 140px;" src="@/assets/gzh.jpg"/></div>
        </v-col>
      </v-row>
      <v-row justify="center" style="border-top:dashed;border-width: 1px; border-color:#fffafa">
        <v-col cols="7" style="text-align: center" class="btxt1">
          Copyright 2017-2020 JOYin Exhibition 沪ICP备11002770号-1
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style lang="less" scoped>
.bottom {
  width: 100%;
  // flex-direction: column;
  height: 240px;
  background: #150005;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // color: #f0f0f0;
  // line-height: 28px;
  // font-size: 14px;
}

ul {
  // background: red;
  display: flex;
  justify-content: flex-start;
  /* 清除ul标签的默认样式 */
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

ul li {
  width: 180px;
  // background-color: red;
  // margin-right: 20px; /* 两个li之间的距离*/
}

ul li a,
.btxt1 {
  /* 设置链接内容显示的格式*/
  display: block; /* 把链接显示为块元素可使整个链接区域可点击 */
  font-size: 14px;
  color: #fffafa;
  // text-align: center;
  padding: 14px 0;
  text-decoration: none; /* 去除下划线 */
}

/deep/ .v-application--wrap {
  min-height: 0;
}
</style>
