<template>
  <div>
    <Header />
    <div class="mycontainer">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from './Footer.vue'
import Header from './Header.vue'
// @ is an alias to /src

export default {
  components: { Header, Footer },
  name: 'Layout',
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style>
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.mycontainer {
  /* background: red; */
  width: 100%;
  padding: 0px;
  /* height: -webkit-calc(100% - 200px);
  height: -moz-calc(100% - 200px); */
  /* height: calc(100% - 200px); */
  min-height: 880px;
  /* height: 880px; */
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
}
</style>