<template>
  <div class="header">
    <div style="width: 200px; text-align: center;padding: 1px;">
      <router-link to="/"><img style="height: 48px;" src="@/assets/logo.png"
      /></router-link>
    </div>
    <ul>
      <li><router-link to="/">首页</router-link></li>
      <li><router-link to="/gongsijianjie">公司简介</router-link></li>
      <li><router-link to="/shichangtouxi">市场透析</router-link></li>
      <li><router-link to="/yewumoshi">业务模式</router-link></li>
      <li><router-link to="/hezuohuoban">合作伙伴</router-link></li>
      <li><router-link to="/hezuojiameng">合作加盟</router-link></li>
    </ul>

    <ul>
      <li
        style="
          width: 300px;
          background: #002FA7;
          text-align: center;
          color: #fff;
          line-height: 50px;
          weight: bold;
        "
      >
        <v-icon color="#fff">mdi-phone</v-icon>
        4000—515141
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Header'
}
</script>
<style lang="less" scoped>
.header {
  background: #e7eaed;

  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  left: 0px;
  top: 0px;
}

.header ul {
  // background: red;
  display: flex;
  justify-content: flex-end;
  /* 清除ul标签的默认样式 */
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.header li {
  width: 150px;
  // background: blue;
  // margin-right: 20px; /* 两个li之间的距离*/
}

.header li a {
  /* 设置链接内容显示的格式*/
  display: block; /* 把链接显示为块元素可使整个链接区域可点击 */
  color: #333333;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none; /* 去除下划线 */
}
.header li a:hover {
  /* 鼠标选中时背景变为黑色 */
  background-color: #111;
  color: #ffffff;
}
// .header ul li ul {
//   /* 设置二级菜单 */
//   width: auto;
//   background: #f2f2f2;
//   position: absolute;
//   display: none; /* 默认隐藏二级菜单的内容 */
// }
// .header ul li ul li {
//   /* 二级菜单li内容的显示 */
//   margin-right: 0;
//   float: none;
//   text-align: center;
// }
// .header ul li:hover ul {
//   /* 鼠标选中二级菜单内容时 */
//   display: block;
// }
</style>
