import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export


import VueVideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VueVideoPlayer)


Vue.component('Footer', Footer)
Vue.component('Header', Header)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
